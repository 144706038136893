import { Component, HostListener, signal } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IconComponent } from '@libs/stand-alone-components/commons/icon/icon.component';
import { TelegramChatWarnComponent } from './telegram-chat-warn/telegram-chat-warn.component';

@Component({
  selector: 'app-tidio-button',

  imports: [CommonModule, IconComponent, TelegramChatWarnComponent],
  templateUrl: './tidio-button.component.html',
  styleUrl: './tidio-button.component.scss',
})
export class TidioButtonComponent {
  showWarn = signal(false);
  @HostListener('document:keydown.escape', ['$event'])
  handleEscape() {
    this.showWarn.set(false);
  }
  openTidio() {
    this.showWarn.set(true);
  }
}
